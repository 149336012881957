<template>
  <div class="content">
    <div class="content-inner">
      <div class="title">{{ event.eventName }}</div>
      <div class="form">
        <el-form :inline="true" class="demo-form-inline">
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <el-form-item label="项目">
                  <el-select v-model="projectId" placeholder="请选择项目" @change="projectChange">
                    <template v-for="item in projectList">
                      <el-option :label="item.projectName" :value="item.projectId"
                                 v-if="item.eventRanksList && item.eventRanksList.length>0"></el-option>
                    </template>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <el-form-item label="榜单">
                  <el-select v-model="eventRanksId" placeholder="请选择榜单" @change="eventRanksChange">
                    <el-option :label="item.name" :value="item.id" v-for="item in ranksList"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content sreach-box">
                <el-input v-model="keyword"
                          :placeholder="currentRanks.nature == 1?'请输入团队名称/号码牌/姓名':'请输入名字和号码牌'"></el-input>
                <el-button type="primary" class="btn" @click="loadCurrentProjectId">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-if="currentRanks.nature == 1">
        <el-table
            stripe
            :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
            :row-style="{ 'text-align': 'center'}"
            class="table"
            :data="tableData"
            style="width: 100%"
            @expand-change="onExpand"
            :key="1"
        >
          <el-table-column align="center" header-align="center" label="排名" prop="index" :formatter="indexFormat"
                           v-if="showContents.indexOf('1')>=0"></el-table-column>
          <el-table-column align="center" header-align="center" label="团队名称" prop="teamName"></el-table-column>
          <el-table-column align="center" header-align="center" label="净成绩" prop="netScore"
                           :formatter="netScoreFormat" v-if="showContents.indexOf('10')>=0"></el-table-column>
          <el-table-column align="center" header-align="center" :label="scoresName" prop="eventScore"
                           :formatter="eventScoreFormat" v-if="showContents.indexOf('7')>=0"></el-table-column>
          <el-table-column prop="currentLap" label="圈数"
                           v-if="showContents.indexOf('3')>=0  && sportRule"></el-table-column>
          <el-table-column prop="currentDistance" label="里程"
                           v-if="showContents.indexOf('3')>=0  && !sportRule"></el-table-column>
          <el-table-column prop="reduceTime" :formatter="timeReductionFormat" label="减时"
                           v-if="showContents.indexOf('9')>=0"></el-table-column>
          <el-table-column prop="penaltyTime" :formatter="timePenaltyFormat" label="罚时"
                           v-if="showContents.indexOf('5')>=0"></el-table-column>
          <!--          <el-table-column prop="status" label="状态" :formatter="statusFormat"-->
          <!--                           v-if="showContents.indexOf('6')>=0"></el-table-column>-->
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table v-show="scope.row.subList && scope.row.subList.length>0" border
                        :header-cell-style="{background:'#eef1f6',color:'#606266'}" :data="scope.row.subList"
                        style="width:100%">

                <el-table-column type="index" width="60" label="序号"
                                 v-if="showContents.indexOf('1')>=0"></el-table-column>
                <el-table-column prop="bibNumber" label="号码牌"></el-table-column>
                <el-table-column prop="fullName" label="姓名"></el-table-column>
                <el-table-column prop="timeSpeed" label="时速" :formatter="formatterTimeSpeed"
                                 v-if="showContents.indexOf('11')>=0">
                </el-table-column>
                <el-table-column prop="country" label="国籍" v-if="showContents.indexOf('12')>=0"></el-table-column>
                <el-table-column prop="age" label="年龄" v-if="showContents.indexOf('2')>=0"></el-table-column>
                <el-table-column prop="currentLap" label="圈数"
                                 v-if="showContents.indexOf('3')>=0  && sportRule">
                </el-table-column>
                <el-table-column prop="currentDistance" label="里程"
                                 v-if="showContents.indexOf('3')>=0  && !sportRule">
                </el-table-column>
                <el-table-column prop="speed" label="配速" :formatter="formatterSpeed"
                                 v-if="showContents.indexOf('4')>=0"></el-table-column>
                <el-table-column prop="timeReduction" :formatter="timeReductionFormat" label="减时"
                                 v-if="showContents.indexOf('9')>=0"></el-table-column>
                <el-table-column prop="timePenalty" :formatter="timePenaltyFormat" label="罚时"
                                 v-if="showContents.indexOf('5')>=0"></el-table-column>
                <el-table-column label="净成绩" prop="netScore"
                                 :formatter="netScoreFormat" v-if="showContents.indexOf('10')>=0">
                </el-table-column>
                <el-table-column :label="scoresName" prop="eventScore"
                                 :formatter="eventScoreFormat" v-if="showContents.indexOf('7')>=0">
                </el-table-column>
                <el-table-column prop="status" label="状态" :formatter="statusFormat"
                                 v-if="showContents.indexOf('6')>=0">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                  <template slot-scope="scope">
                    <el-button @click="toScore(scope.row)" type="text" size="small">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-table
            :key="2"
            stripe
            :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
            :row-style="{ 'text-align': 'center'}"
            class="table"
            :data="tableData"

            style="width: 100%"
        >
          <el-table-column prop="index" :formatter="formatterIndex" width="60" label="序号"
                           v-if="showContents.indexOf('1')>=0"></el-table-column>
          <el-table-column prop="bibNumber" label="号码牌"></el-table-column>
          <el-table-column prop="fullName" label="姓名"></el-table-column>
          <el-table-column prop="timeSpeed" label="时速" :formatter="formatterTimeSpeed"
                           v-if="showContents.indexOf('11')>=0">
          </el-table-column>
          <el-table-column prop="country" label="国籍" v-if="showContents.indexOf('12')>=0"></el-table-column>
          <el-table-column prop="age" label="年龄" v-if="showContents.indexOf('2')>=0"></el-table-column>
          <el-table-column prop="currentLap" label="圈数"
                           v-if="showContents.indexOf('3')>=0 && sportRule">
          </el-table-column>
          <el-table-column prop="currentDistance" label="里程"
                           v-if="showContents.indexOf('3')>=0 &&!sportRule">
          </el-table-column>
          <el-table-column prop="speed" label="配速" :formatter="formatterSpeed"
                           v-if="showContents.indexOf('4')>=0"></el-table-column>
          <el-table-column label="净成绩" prop="netScore"
                           :formatter="netScoreFormat" v-if="showContents.indexOf('10')>=0">
          </el-table-column>
          <el-table-column :label="scoresName" prop="eventScore"
                           :formatter="eventScoreFormat" v-if="showContents.indexOf('7')>=0">
          </el-table-column>
          <el-table-column prop="timeReduction" :formatter="timeReductionFormat" label="减时"
                           v-if="showContents.indexOf('9')>=0"></el-table-column>
          <el-table-column prop="timePenalty" :formatter="timePenaltyFormat" label="罚时"
                           v-if="showContents.indexOf('5')>=0"></el-table-column>
          <el-table-column prop="status" label="状态" :formatter="statusFormat"
                           v-if="showContents.indexOf('6')>=0">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="100">
            <template slot-scope="scope">
              <el-button @click="toScore(scope.row)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>


      <el-pagination
          style="text-align: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--    <div class="no-data">没有发布</div>-->
  </div>
</template>

<script>
import {
  frontSearchByEventId,
  singleScoresPage,
  teamScoresPage,
  getCurrentProjectId,
  listIndividualScoresListByTeamId,
  getEvent
} from '@/utils/request';
import {timeFilter} from '@/utils/dateUtil';

const date = new Date();
export default {
  data() {
    return {
      type: '1',
      eventId: '',
      eventRanksId: '',
      projectId: '',
      teamId: null,
      age: '',
      keyword: '',
      tableData: [],
      projectList: [],
      data: {},
      current: 1,
      size: 20,
      total: 0,
      event: {},
      currentProjectId: null,
      inner: false,
    }
  },
  computed: {
    scoresName() {
      if ('962047' == this.eventId) {
        return '总成绩';
      }
      return '枪声成绩';
    },
    sportRule() {
      let boo = false;
      this.projectList.forEach(item => {
        if (item.projectId == this.projectId) {
          boo = item.sportRule == 2;
        }
      })
      return boo;
    },
    ranksList() {
      let arr = [];
      this.projectList.forEach(item => {
        if (item.projectId == this.projectId) {
          arr = item.eventRanksList;
        }
      })
      return arr
    },
    currentRanks() {
      let result = {
        nature: 1
      };
      this.ranksList.forEach(item => {
        if (item.id == this.eventRanksId) {
          result = item;
        }
      })
      return result
    },
    showContents() {
      if (!this.ranksList || !this.eventRanksId) {
        return [];
      }
      let content = []
      this.ranksList.forEach(item => {
        if (item.id == this.eventRanksId) {
          content = item.content.split(',');
        }
      })
      return content;
    }
  },
  mounted() {
    this.eventId = this.$route.query.eventId
    if (!this.eventId) {
      return
    }
    if (this.$route.query.inner == 1) {
      this.inner = true
    }
    getEvent(this.eventId).then(
        (res) => {
          that.event = res.data.data;
          document.title = that.event.eventName;
          if (!(that.event.status == 3 || that.event.status == 2) && !this.inner) {
            this.$router.push({
              path: '/pages/event/list', query: {}
            })
          }
          if (that.event.publis == 1) {
            this.$router.push({
              path: '/pages/index/index', query: {
                eventId: this.eventId,
                inner: this.$route.query.inner
              }
            })
          }
        },
        (err) => {
          console.log(err);
        }
    )
    let that = this;
    frontSearchByEventId({eventId: this.eventId}).then(
        (res) => {
          if (res.data && res.data.code == 200) {
            that.projectList = res.data.data;
            if (that.projectList.length > 0) {
              that.projectId = that.projectList[0].projectId;
              if (that.ranksList.length > 0) {
                that.eventRanksId = that.ranksList[0].id;
                that.loadTableData();
              }
            }
          } else {
            this.$message({
              message: '服务器繁忙',
              type: 'warning'
            });
          }
        },
        (err) => {
          console.log(err);
        }
    )
  },
  filters: {
    //处理函数
    timeFilter(value) {
      return timeFilter(value)
    }
  },

  methods: {

    onExpand(row, expandedRows) {
      // 判断当前展开行
      console.log(row, expandedRows)
      if (expandedRows.find(ele => ele === row) && row.subList.length <= 0) {
        listIndividualScoresListByTeamId(this.currentRanks.id, row.teamId).then((res) => {
              row.subList = res.data.data;
            },
            (err) => {
              console.log(err);
            }
        )
      }
    },
    timeFilter2(seconds) {
      let boo = false;
      if (seconds < 0) {
        boo = true;
        console.log('timeFilter', seconds);
        seconds = Math.abs(seconds);
      }
      if (seconds < 60) {
        return `${boo ? '-' : ''}${seconds}″`;
      }
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `${boo ? '-' : ''}${minutes}′${seconds}″`;
    },

    formatterSpeed(row, column) {
      let value = row.netScore;
      if (row.netScore <= 0) {
        value = row.eventScore
      }
      if (row.currentDistance <= 0 || value <= 0) {
        return '--';
      }
      const seconds = parseInt(value / 1000 / row.currentDistance);
      return this.timeFilter2(seconds);
    },
    formatterIndex(row, column) {
      return row.index + 1;
    },
    formatterTimeSpeed(row, column) {
      let value = row.netScore;
      if (row.netScore <= 0) {
        value = row.eventScore
      }
      if (row.currentDistance <= 0 || value <= 0) {
        return '--';
      }
      return (row.currentDistance / (value / (1000 * 60 * 60))).toFixed(2);
    },
    toScore(row, event, column) {
      this.$router.push({
        path: '/pages/score/single/single', query: {
          bibNumber: row.bibNumber,
          eventRanksId: this.eventRanksId
        }
      })
    },
    projectChange() {
      this.eventRanksId = this.ranksList[0].id;
      this.loadTableData()
    },
    eventRanksChange(v) {
      this.loadTableData()
    },
    statusFormat(row, column) {
      if (row.status == 0) {
        return 'DNS';
      }
      if (row.status == 8) {
        return 'DNF';
      }
      if (row.status == 9) {
        return row.currentCheckpoint ? row.currentCheckpoint : '比赛中'
      }
      if (row.status == 10) {
        return '已完赛';
      }
      if (row.status == 7) {
        return '已退赛';
      }
      if (row.status == 6) {
        return '无效';
      }
      return ''
    },

    timePenaltyFormat(row, column) {
      return timeFilter(row.netScore)
    },
    indexFormat(row, column) {
      return row.index + 1;
    },
    timeReductionFormat(row, column) {
      return timeFilter(row.netScore)
    },
    netScoreFormat(row, column) {
      return timeFilter(row.netScore)
    },
    eventScoreFormat(row, column) {
      return timeFilter(row.eventScore)
    },
    handleSizeChange(size) {
      this.size = size;
      this.loadTableData()
    },
    handleCurrentChange(current) {
      this.current = current;
      this.loadTableData()
    },
    typeChange() {
      if (this.type == 0) {
        this.$router.push({
          path: '/pages/score/rank/team', query: {
            eventRanksId: this.eventRanksId,
          }
        })
      }
    },

    loadTableData() {
      let params = {
        size: this.size,
        current: this.current
      }
      if (this.keyword) {
        params['keyword'] = this.keyword
      }
      let that = this;
      if (this.currentRanks.nature == 1) {
        teamScoresPage(this.currentRanks.id, params).then(
            (res) => {
              const records = res.data.data.records;
              records.forEach(item => {
                item['subList'] = [];
              })
              that.tableData = records;
              that.total = res.data.data.total;
            },
            (err) => {
              console.log(err);
            }
        )
      } else {
        singleScoresPage(this.currentRanks.id, params).then(
            (res) => {
              that.tableData = res.data.data.records;
              that.total = res.data.data.total;
            },
            (err) => {
              console.log(err);
            }
        )
      }

    },
    handleEdit(index, row) {
      console.log(index, row);
      var that = this;
      that.dialogFormVisible = true;
      that.form.date = row.date;
      that.form.name = row.name;
    },
    addmessage() {
      var that = this;
      that.dialogAddFormVisible = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功',
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
    },
    loadCurrentProjectId() {
      this.tableData = []
      if (this.keyword) {
        getCurrentProjectId({
          eventId: this.eventId,
          keyword: this.keyword
        }).then(
            (res) => {
              console.log('aaa', res)
              if (!res.data.data.projectId) {
                this.tableData = [];
                return;
              }
              this.projectId = res.data.data.projectId
              this.projectChange();
            },
            (err) => {
              console.log(err);
            }
        )
      } else {
        this.loadTableData();
      }
    }
  },
};
</script>
<style scoped lang="scss">
.content {
  background: #F8F8F8;
  height: 100vh;
  padding-bottom: 40px;

  .content-inner {
    max-width: 1260px;

    .title {
      height: 72px;
      line-height: 72px;
      background: #FFFFFF;
      text-align: left;
      padding-left: 24px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .form {
      background: #fff;
      margin-top: 14px;
      padding: 14px 0;

      .sreach-box {
        display: flex;

        .btn {
          background: #34C5F6;
          border: none;
          margin-left: 8px;
        }
      }
    }

    .table {
      margin-top: 14px;
    }

    .header_row {
      display: flex;
      flex-direction: row;
    }

    .addbutton {
      margin-right: 20px;
    }

    margin: auto;
  }

}


.grid-content {
  border-radius: 4px;

}

.el-form-item {
  margin-bottom: 0;
}

.no-data {
  padding-top: 300px;
}
</style>
