<template>
  <div class="body"
       :style="largeScreen.bgUrl?'background: url('+largeScreen.bgUrl+') no-repeat;background-size:100% 100%':''">
    <img :src="largeScreen.logo" v-if="largeScreen.logo" style="width: 100%; display: block;background-size: no-repeat">
    <div class="event-name" v-else>{{ largeScreen.name }}</div>
    <div class="title1" v-if="isOne&&tables.length>0&&tables[0].eventRanks">{{ tables[0].eventRanks.name }}</div>
    <div class="row">
      <div class="row-item" :class="{'row-item2':tables.length ==3}" v-for="(table,index) in tables"
           :key="index">
        <div class="row-body">
          <div :class="{title1:index%2==1,title2:index%2==0}" v-if="!isOne&&table.eventRanks">{{
              table.eventRanks.name
            }}
          </div>
          <div class="list" v-if="table.eventRanks&&table.eventRanks.nature ==1">
            <div class="list-row list-title">
              <div class="blue-bg" style="background: inherit">名次</div>
              <div class="list-row-item">团队名称</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'10')">净成绩</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'7')">枪声成绩</div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3')&& table.eventProject&&table.eventProject.sportRule ==2">圈数
              </div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3')&& table.eventProject&&table.eventProject.sportRule ==1">里程
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'9')">减时</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'5')">罚时</div>
            </div>
            <div class="list-row" v-for="item in table.tableData">
              <div class=" blue-bg">{{ item.index + 1 }}</div>
              <div class="list-row-item  yellow-color">{{ item.teamName }}</div>
              <div class="list-row-item  green-color" v-if="showColumn(table.eventRanks,'10')">
                {{ timeFilterFormat(item.netScore) }}
              </div>
              <div class="list-row-item  green-color" v-if="showColumn(table.eventRanks,'7')">{{
                  timeFilterFormat(item.eventScore)
                }}
              </div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3')&& table.eventProject&&table.eventProject.sportRule ==2">
                {{ item.currentLap }}
              </div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3')&& table.eventProject&&table.eventProject.sportRule ==1">
                {{ item.currentDistance }}
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'9')">{{
                  timeFilterFormat(item.reduceTime)
                }}
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'5 ')">{{
                  timeFilterFormat(item.penaltyTime)
                }}
              </div>
            </div>
          </div>
          <div class="list" v-else>
            <div class="list-row list-title">
              <div class="blue-bg" style="background: inherit">序号</div>
              <div class="list-row-item">号码牌</div>
              <div class="list-row-item">姓名</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'11')">时速</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'12')">国籍</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'2')">年龄</div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3')&& table.eventProject&&table.eventProject.sportRule ==2">圈数
              </div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3')&& table.eventProject&&table.eventProject.sportRule ==1">里程
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'4')">配速</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'9')">减时</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'5')">罚时</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'10')">净成绩</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'7')">枪声成绩</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'6')">状态</div>
            </div>
            <!--          -->
            <div class="list-row" v-for="item in table.tableData">
              <div class=" blue-bg">{{ item.index + 1 }}</div>
              <div class="list-row-item">{{ item.bibNumber }}</div>
              <div class="list-row-item  yellow-color">{{ item.fullName }}</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'11')">{{ formatterTimeSpeed(item) }}</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'12')">{{ item.country }}</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'2')">{{ item.age }}</div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3') && table.eventProject&&table.eventProject.sportRule ==2">
                {{ item.currentLap }}
              </div>
              <div class="list-row-item"
                   v-if="showColumn(table.eventRanks,'3') && table.eventProject&&table.eventProject.sportRule ==1">
                {{ item.currentDistance }}
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'4')">{{ formatterSpeed(item) }}</div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'9')">{{
                  timeFilterFormat(item.timeReduction)
                }}
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'5')">{{
                  timeFilterFormat(item.timePenalty)
                }}
              </div>
              <div class="list-row-item green-color" v-if="showColumn(table.eventRanks,'10')">{{
                  timeFilterFormat(item.netScore)
                }}
              </div>
              <div class="list-row-item green-color" v-if="showColumn(table.eventRanks,'7')">{{
                  timeFilterFormat(item.eventScore)
                }}
              </div>
              <div class="list-row-item" v-if="showColumn(table.eventRanks,'6')">{{ statusFormat(item) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  largeScreenDetail, listLargeScreenRanksData
} from '@/utils/request';
import {timeFilter} from '@/utils/dateUtil';

export default {
  name: 'electron',
  data() {
    return {
      id: '',
      largeScreen: {},
      tables: [],
      ranksList: [],
      isOne: false
    }
  },
  mounted() {
    this.id = this.$route.query.id
    if (!this.id) {
      return
    }
    this.detail();
  },
  methods: {
    statusFormat(row, column) {
      if (row.status == 0) {
        return 'DNS';
      }
      if (row.status == 8) {
        return 'DNF';
      }
      if (row.status == 9) {
        return row.currentCheckpoint ? row.currentCheckpoint : '比赛中'
      }
      if (row.status == 10) {
        return '已完赛';
      }
      if (row.status == 7) {
        return '已退赛';
      }
      if (row.status == 6) {
        return '无效';
      }
      return ''
    },
    formatterTimeSpeed(row) {
      let value = row.netScore;
      if (row.netScore <= 0) {
        value = row.eventScore
      }
      if (row.currentDistance <= 0 || value <= 0) {
        return '--';
      }
      return (row.currentDistance / (value / (1000 * 60 * 60))).toFixed(2);
    },
    formatterSpeed(row) {
      let value = row.netScore;
      if (row.netScore <= 0) {
        value = row.eventScore
      }
      if (row.currentDistance <= 0 || value <= 0) {
        return '--';
      }
      const seconds = parseInt(value / 1000 / row.currentDistance);
      return this.timeFilter2(seconds);
    },
    timeFilterFormat(value) {
      return timeFilter(value)
    },
    detail() {
      largeScreenDetail(this.id).then(
          (res) => {
            if (res.data.code == 200) {
              this.largeScreen = res.data.data;
              let contents = JSON.parse(this.largeScreen.content);
              if (!contents) {
                return
              }
              contents.forEach(content => {
                if (content.eventRanksId) {
                  content.eventRanksId.forEach(eventRanksId => {
                    let table = {
                      eventRanksId: eventRanksId,
                      size: content.size,
                      current: 1,
                      total: 1000,
                      stage: content.stage,
                      ranksStart: content.ranksStart,
                      ranksEnd: content.ranksEnd,
                      eventRanks: null,
                      eventProject: null,
                      tableData: [],
                    }
                    this.tables.push(table)
                  })
                }
              })
              if (this.tables.length == 1) {
                this.isOne = true;
                let table = JSON.parse(JSON.stringify(this.tables[0]))
                table.current = 2;
                this.tables.push(table)
              }
              this.scheduled();
            }
          },
          (err) => {
            console.log(err);
          }
      )
    },
    scheduled() {
      if (this.tables.length < 1) {
        return
      }
      // 直接请求
      for (let i = 0; i < this.tables.length; i++) {
        this.queryRanks(this.tables[i], i)
      }
      //触发定时任务
      for (let i = 0; i < this.tables.length; i++) {
        let table = this.tables[i]
        let stage = 10;
        if (table.stage) {
          stage = parseInt(table.stage);
        }
        let timer = setInterval(() => {
          console.log('定时任务执行 eventRanksId ', table.eventRanksId)
          this.queryRanks(table, i)
        }, stage * 1000)
      }
    },
    queryRanks(table, index) {
      let that = this;
      if (table.size * (table.current - 1) > table.total) {
        if (this.isOne) {
          if (table.size * (table.current - 2) > table.total) {
            location.reload();
          }
          return;
        } else {
          table.current = 1;
        }
      }
      console.log('table============>', table)
      listLargeScreenRanksData({
        size: table.size,
        current: table.current,
        ranksStart: table.ranksStart,
        ranksEnd: table.ranksEnd,
        eventRanksId: table.eventRanksId
      }).then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              if (this.isOne) {
                table.current += 2;
              } else {
                table.current++;
              }

              table.total = data.total;
              table.eventRanks = data.eventRanks;
              table.eventProject = data.eventProject;
              if (data.eventRanks.nature == 1) {
                table.tableData = data.teamScoresList;
              } else {
                table.tableData = data.singleScoresList;
              }
              if (index || index == 0) {
                console.log('asdasd', index)
                that.$set(that.tables, index, table)
              }
            }
          },
          (err) => {
            console.log(err);
          })
    },
    showColumn(eventRanks, value) {
      if (!eventRanks) {
        return [];
      }
      return eventRanks.content.indexOf(value) >= 0;
    }
  }
}
</script>

<style scoped lang="scss">
.body {
  background: linear-gradient(135deg, #151521, #2d404d);
  color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: scroll;

  .event-name {
    padding: 20px;
    font-size: 40px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  }

  .title1 {
    font-family: "黑体";
    font-size: 28px;
    color: #e2e4e8;
    font-weight: 700;
    width: 100%;
    height: 63px;
    line-height: 63px;
    margin: auto;
    background: url('https://timesport-1313948175.cos.ap-shanghai.myqcloud.com/20240516/22939f34-910b-44e8-816e-eadad055caab.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 20px;
  }

  .title2 {
    font-family: "黑体";
    font-size: 28px;
    color: #e2e4e8;
    font-weight: 700;
    width: 100%;
    height: 63px;
    line-height: 63px;
    margin: auto;
    background: url('https://timesport-1313948175.cos.ap-shanghai.myqcloud.com/20240516/3bc7f543-e615-44db-830a-cbc4bcf1f8c2.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .row-item {
      width: 50%;

      .row-body {
        padding: 0 20px;
      }


      .list {
        .list-title {
          font-size: 20px;
          color: #d2d2e0;
          font-weight: 700;
        }

        .list-row {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          .list-row-item {
            height: 30px;
            line-height: 30px;
            padding: 16px;
            font-size: 25px;
            flex: 1;
          }

          .blue-bg {
            height: 30px;
            line-height: 30px;
            padding: 16px;
            font-size: 25px;
            background: rgba(52, 150, 246, 0.3);
            border-radius: 10px;
            width: 120px;
          }
        }
      }
    }

    .row-item2 {
      width: 33%;
    }
  }


  .green-color {
    color: #9cb872;
  }

  .yellow-color {
    color: #d7a251;
  }
}
</style>
