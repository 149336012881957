<template>
  <div class="content">
    <div class="content-inner" v-if="data.project">
      <div class="title">{{data.eventName}}</div>
      <div class="form">
        <el-form :inline="true" class="demo-form-inline">
          <el-row>
            <el-col :span="6" v-if="data.project&&data.project.projectType == 2">
              <div class="grid-content bg-purple">
                <el-form-item label="榜单类型">
                  <el-select v-model="type" placeholder="请选择榜单类型" @change="typeChange" >
                    <el-option label="团队成绩" value="0"></el-option>
                    <el-option label="个人成绩" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" v-if="data.project&&data.project.projectType == 2">
              <div class="grid-content bg-purple-light">
                <el-form-item label="团队">
                  <el-select v-model="teamId" placeholder="请选择团队" clearable>
                    <el-option :label="item.teamName" :value="item.id" v-for="item in data.teams"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" :offset="6">
              <div class="grid-content sreach-box">
                <el-input v-model="keyword" placeholder="请输入团队名"></el-input>
                <el-button type="primary" class="btn" @click="loadTableData">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-table
          stripe
          :header-cell-style="{background:'#34C5F6', color:'white', 'line-height':'48px'}"
          :row-style="{ 'text-align': 'center'}"
          class="table"
          :data="
        tableData
      "
          style="width: 100%"
      >
        <el-table-column align="center" header-align="center" label="排名" type="index">
        </el-table-column>
        <el-table-column align="center" header-align="center" label="团队名称" prop="teamName"></el-table-column>
        <el-table-column align="center" header-align="center" label="净成绩" prop="netScore"
                         :formatter="netScoreFormat"></el-table-column>
        <el-table-column align="center" header-align="center" label="枪声成绩" prop="eventScore"
                         :formatter="eventScoreFormat"></el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table v-show="scope.row.individualScores.length>0" border
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" :data="scope.row.individualScores"
                      style="width:100%">
              <el-table-column prop="bibNumber" label="号码牌"></el-table-column>
              <el-table-column prop="fullName" label="姓名"></el-table-column>
              <el-table-column label="净成绩" prop="netScore"
                               :formatter="netScoreFormat"></el-table-column>
              <el-table-column label="枪声成绩" prop="eventScore"
                               :formatter="eventScoreFormat"></el-table-column>
              <el-table-column prop="status" label="状态" :formatter="statusFormat"></el-table-column>
            </el-table>
          </template>

        </el-table-column>
      </el-table>
      <el-pagination
          style="text-align: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


    <div v-else class="no-data">没有数据</div>
  </div>
</template>

<script>
import {eventRanksInfo, teamScores} from "@/utils/request";
import {timeFilter} from "@/utils/dateUtil";

const date = new Date();
export default {
  data() {
    return {
      type: "0",
      data: {},
      keyword: "",
      teamId: null,
      tableData: [],
      current: 1,
      size: 20,
      total: 0,
    }
  },
  mounted() {
    this.eventRanksId = this.$route.query.eventRanksId
    if (!this.eventRanksId) {
      return
    }

    let that = this;
    eventRanksInfo(this.eventRanksId).then(
        (res) => {
          that.data = res.data.data;
          console.log("that.data ", that.data);
        },
        (err) => {
          console.log(err);
        }
    )

    this.loadTableData()
  },
  filters: {
    //处理函数
    timeFilter(value) {
      return timeFilter(value)
    }
  },
  methods: {
    statusFormat(row, column) {
      if (row.status == 0) {
        return "DNS";
      }
      if (row.status == 8) {
        return "DNF";
      }
      if (row.status == 9) {
        return  row.currentCheckpoint ? row.currentCheckpoint : "比赛中"
      }
      if (row.status == 10) {
        return "已完赛";
      }
      if (row.status == 7) {
        return "已退赛";
      }
      if (row.status == 6) {
        return "无效";
      }
      return ""
    },
    netScoreFormat(row, column) {
      return timeFilter(row.netScore)
    },
    eventScoreFormat(row, column) {
      return timeFilter(row.eventScore)
    },
    typeChange() {
      if (this.type == 1) {
        this.$router.push({
          path: "/pages/score/rank/single", query: {
            eventRanksId: this.eventRanksId,
          }
        })
      }
    },
    handleSizeChange(size) {
      this.size = size;
      this.loadTableData()
    },
    handleCurrentChange(current) {
      this.current = current;
      this.loadTableData()
    },
    loadTableData() {
      let params = {
        size: this.size,
        current: this.current
      }
      if (this.keyword) {
        params["keyword"] = this.keyword
      }
      if (this.teamId) {
        params["teamId"] = this.teamId
      }
      let that = this;
      teamScores(this.eventRanksId, params).then(
          (res) => {
            console.log("data", res)
            that.tableData = res.data.data.records;
            that.total = res.data.data.total;
          },
          (err) => {
            console.log(err);
          }
      )
    },
    handleEdit(index, row) {
      console.log(index, row);
      var that = this;
      that.dialogFormVisible = true;
      that.form.date = row.date;
      that.form.name = row.name;
    },
    addmessage() {
      var that = this;
      that.dialogAddFormVisible = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  background: #F8F8F8;
  height: 100vh;

  .content-inner {
    max-width: 1260px;

    .title {
      height: 72px;
      line-height: 72px;
      background: #FFFFFF;
      text-align: left;
      padding-left: 24px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .form {
      background: #fff;
      margin-top: 14px;
      padding: 14px 0;

      .sreach-box {
        display: flex;

        .btn {
          background: #34C5F6;
          border: none;
          margin-left: 8px;
        }
      }
    }

    .table {
      margin-top: 14px;
    }

    .header_row {
      display: flex;
      flex-direction: row;
    }

    .addbutton {
      margin-right: 20px;
    }

    margin: auto;
  }

}


.grid-content {
  border-radius: 4px;
}

.el-form-item {
  margin-bottom: 0;
}

.no-data{
  padding-top: 300px;
}
</style>
